import styled, { css } from 'styled-components';

import downloadConnectorHeader from 'src/Assets/Images/zip-teams-download.png';
import iconZip from 'src/Assets/Images/icon-zip.png';

export const EditionWrapper = styled.div`
  margin: 0 auto 60px auto;
  width: 100%;

  .appearance {
    display: flex;
    flex-direction: row;
    .main-color {
      flex-direction: column;

      .inner-content {
        display: flex;
        align-items: center;

        .color-placeholder {
          margin-right: 5px;
          width: 80px;
        }
        .color-picker {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .heading {
            color: ${({ theme }) => theme.color_grey__600};
          }
        }
      }
    }
    .main-color {
      display: flex;
      > div {
        margin-right: 5px;
      }
    }
  }

  .main-logo,
  .bot-reactions,
  .livechat-logo,
  .onboarding-images {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;

    .image-upload-large {
      .inner-content {
        border: none;
        background: none;
        padding: 0;
      }
    }
  }

  .css-editor,
  .js-editor {
    padding: 0;
    overflow: hidden;
    .action-buttons {
      border-top: 1px solid ${({ theme }) => theme.color_grey__400};
      padding: 20px;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
      }
    }
  }

  .banner-panel {
    .options-panel {
      margin-top: 30px;

      .link-action {
        align-items: flex-end;
        .moreLink {
          width: 100%;
        }
      }
    }
    .controlled-dropdown {
      margin-right: 0;
      > .p-dropdown {
        width: 150px;
        height: 40px;
        .p-dropdown-label {
          display: flex;
          align-items: center;
        }
      }
      > .label {
        font-size: 12px;
      }
    }
  }

  .integration {
    .message {
      justify-content: center;
      display: flex;
    }
  }

  &.connector-informations {
    max-width: 960px;
  }

  &.connector-download {
    max-width: 518px;

    .inner-content {
      padding: 0;
      overflow: hidden;

      .header {
        background: url(${downloadConnectorHeader}) no-repeat center center;
        background-size: cover;
        height: 225px;
      }

      .content {
        padding: 20px;

        .title {
          font-size: 21px;
          font-weight: bold;
        }

        .subtitle {
          color: ${({ theme }) => theme.color_grey__500};
        }

        .file {
          padding: 30px 0;
          .info {
            width: 328px;
            margin: 0 auto;
            border: 1px solid ${({ theme }) => theme.color_grey__400};
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px;

            .img {
              background: url(${iconZip}) no-repeat center center;
              background-size: cover;
              width: 28px;
              height: 33px;
              margin-right: 20px;
            }
            .filename {
              font-size: 16px;
              color: ${({ theme }) => theme.color_grey__600};
            }
          }
        }
        .message {
          margin-bottom: 5px;
        }
        .action {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &.connector-configuration {
    .general {
      > div {
        align-items: center;
      }
      .controlled-input {
        width: 100%;
      }
    }
    .application {
      .controlled-input {
        width: 400px;
      }
    }
    .application,
    .identity {
      .actionWrapper {
        align-items: flex-start;
        .title {
          font-size: 16px;
          margin-bottom: 15px;
          color: ${({ theme }) => theme.color_grey__600};
        }
      }
    }
    .links {
      .actionWrapper .content,
      .controlled-input {
        width: 100%;
      }
    }
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
  align-items: flex-start;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 15px;
  align-items: flex-start;
`;

export const InnerContent = styled.div`
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #5060704d;
  border-radius: 6px;
  margin-bottom: 40px;
  padding: 20px;
  width: 100%;
  position: relative;

  ${({ disabled }: { disabled?: boolean }) =>
    disabled &&
    css`
      &:after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: ${({ theme }) => theme.color_white};
        opacity: 0.5;
      }
    `}

  .jsoneditor {
    border: none;
    margin-top: 10px;
  }
  .jsoneditor-menu {
    background-color: ${({ theme }) => theme.color_action};
  }
`;

export const Container = styled.div`
  ${({ hidden }: { hidden?: boolean }) =>
    hidden &&
    css`
      display: none;
    `}
`;

export const CallBotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-bottom: 60px;

  .p-panel {
    width: 100%;
  }
  .p-panel-title {
    font-size: 16px;
    color: ${({ theme }) => theme.color_grey__600};
  }
  .p-panel-content {
    img {
      max-width: 60px;
      max-height: 60px;
    }
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;

  .actions-wrapper {
    margin-top: 20px;
  }
`;

export const CallBotEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  .button-back {
    display: flex;
    justify-content: center;
  }

  .button {
    width: 20%;
  }
`;

export const CallBotCardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex: 1;
  justify-content: space-around;
  gap: 30px;
  .p-card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .callbot-phone {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  h1 {
    font-size: 14px;
    color: ${({ theme }) => theme.color_grey__600};
  }

  .tts-card {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: space-around;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }
  }

  .callbot-tts-config {
    width: 50%;
  }
  p {
    font-size: 14px;
    color: ${({ theme }) => theme.color_information};
  }
`;

export const CallBotCarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

export const TtsPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: center;
    flex-grow: 0;
  }
  .p-datatable-table {
    width: 100%;
    resize: none;
  }
  .p-column-header-content {
    display: flex;
    justify-content: center;
    font-size: 14px;
  }

  .input-wrapper {
    margin: 0px;
  }

  .tts-header {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    color: ${({ theme }) => theme.color_grey__600};
    p {
      white-space: nowrap;
    }
  }
  .test-audio-column {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p {
      max-width: 100px;
      white-space: nowrap;
      overflow: visible;
    }
  }
  .name-column {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-right: 10px;
  }
`;
