import { Column, DataTable as PrimeDataTable } from './Style';

import { CSSProperties } from 'styled-components';
import { ReactNode } from 'react';

interface ColumnProps {
  id: string;
  title: string;
  style?: CSSProperties;
  body?: any;
  filter?: boolean;
  field?: string;
  filterField?: string;
  filterHeader?: ReactNode;
  filterPlaceholder?: string;
  filterElement?: any;
  showFilterMenu?: boolean;
}

interface DataTableProps {
  data: any;
  rowClassName: any;
  header?: any;
  columns: ColumnProps[];
  paginatorTemplate?: string;
  rows?: number;
  onRowClick?: (e) => void;
  paginator?: boolean;
  rowsPerPage?: boolean;
  rowsPerPageOptions?: number[];
  filters?: any;
  filterDisplay?: any;
  rowHover?: boolean;
}

const defaultRowsPerPageOptions = [];

const DataTable = ({
  data,
  filters,
  filterDisplay,
  header,
  columns,
  onRowClick,
  rowClassName,
  paginatorTemplate = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
  rows = 10,
  rowsPerPage = false,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  paginator,
  rowHover,
}: DataTableProps) => {
  const renderColumns = () =>
    columns?.map((column) => (
      <Column
        key={column.id}
        field={column.id}
        filterElement={column.filterElement}
        filterHeader={column.filterHeader}
        header={column.title}
        body={column.body}
        filterField={column.filterField}
        style={column?.style || {}}
        filter={column.filter}
        filterPlaceholder={column.filterPlaceholder}
        showFilterMenu={column.showFilterMenu}
      />
    ));

  return (
    <PrimeDataTable
      value={data}
      paginator={paginator || data?.length > rows}
      header={header}
      rowClassName={rowClassName}
      paginatorTemplate={paginatorTemplate}
      rows={(rowsPerPage && rowsPerPageOptions[0]) || rows}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowClick={onRowClick}
      filters={filters}
      filterDisplay={filterDisplay}
      rowHover={rowHover}
    >
      {renderColumns()}
    </PrimeDataTable>
  );
};

export default DataTable;
