import Api from '@dydu_ai/dydu-api';
import { DyduStorage } from './Constants';
import axios, { AxiosResponse } from 'axios';

export const casUrl = window?.DYDU_TARGETS?.cas;
const url_callbot = window?.DYDU_TARGETS?.callbot_url;
// base64 of {"appWelcomeSentence":"cas.login.welcome.channels"}
export const casLoginMessage = 'eyJhcHBXZWxjb21lU2VudGVuY2UiOiJjYXMubG9naW4ud2VsY29tZS5jaGFubmVscyJ9';
export const casClientName = 'ChannelsCasClient';
export const encodedServiceUrl = encodeURIComponent(window.location.origin + '/?client_name=' + casClientName);

const getGatewayUrl = () => {
  if (sessionStorage.getItem(DyduStorage.PROXY)) {
    return sessionStorage.getItem(DyduStorage.PROXY);
  } else if (window?.DYDU_TARGETS?.backend) {
    return window?.DYDU_TARGETS?.backend;
  }
  return process.env.REACT_APP_GATEWAY_URL;
};

export function getClientApi() {
  const headers = {};
  return Api.getInstance(getGatewayUrl(), headers);
}

export const getCallBotConfigurations = async (atriaServer: string, botUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.get(url_callbot + `/instances/${atriaServer}/${botUUID}`, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const updateCallBotVoice = async (instanceUUID: string, data: object) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.post(url_callbot + `/instances/${instanceUUID}`, data, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllCallBotVoicesAvailables = async (instanceUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.get(url_callbot + `/tts/voices/${instanceUUID}`, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const generateTts = async (
  instanceUUID: string,
  voice: string,
  content: string,
  provider: string,
  language: string
) => {
  const headers = {
    accept: 'application/octet-stream',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const params = {
    provider: provider,
    language: language,
    voice: voice,
    content: content,
  };
  const responseType = 'blob';
  try {
    const res: AxiosResponse = await axios.post(url_callbot + `/tts/generate/${instanceUUID}`, '', {
      headers,
      params,
      responseType,
    });
    return res.data;
  } catch (error) {
    return error;
  }
};
